<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>姓名：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.user_name" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>学工号：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.card_number" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne2">
                        <span class="baseOperateText">已选 <strong
                                style="color: rgba(65, 148, 242, 1);">{{this.tableDataSel.length}}</strong> 位用户</span>
            </div>
            <div class="baseOperateOne2" style="margin-left: 16px;" v-if="authorityStatus.del.show">
                <el-button type="danger" @click="batchDel">批量删除</el-button>
            </div>
            <div class="baseOperateOne">
                <el-button type="primary" @click="openExport" v-if="authorityStatus.teacherExport.show">导出招生老师
                </el-button>
            </div>

            <div class="baseOperateOne" style="margin-right: 10px;">
                <el-button type="primary" @click="openAddTeacher" v-if="authorityStatus.fileAdd.show">添加招生老师</el-button>
            </div>
            <!--<div class="baseOperateOne" style="margin-right: 10px;">-->
            <!--<el-button type="primary" @click="openImport" v-if="authorityStatus.fileAdd.show">添加招生老师(批量导入)-->
            <!--</el-button>-->
            <!--</div>-->
            <!--<div class="baseOperateOne" style="margin-right: 10px;">-->
            <!--<el-button type="primary" @click="openOrganization" v-if="authorityStatus.zuzhijiagouAdd.show">-->
            <!--添加招生老师(从组织架构导入)-->
            <!--</el-button>-->
            <!--</div>-->
        </div>
        <div class="baseTable">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         :publicTableConfigure="tableConfigure" @selAllDataFun="tableMsgFromSon($event)">
                <el-table-column prop="card_number" label="学工号"></el-table-column>
                <el-table-column prop="user_name" label="姓名"></el-table-column>
                <el-table-column prop="phone" label="联系方式"></el-table-column>
                <el-table-column prop="name" label="状态">
                    <template #default="scope">
                        <el-tag type="success" v-if="scope.row.status == 1">正常</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.status == 0">冻结</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary" @click.prevent="openQrcode(scope.row)">
                            招生二维码
                        </el-link>
                        <!--<el-link class="tableButton" type="primary" @click.prevent="openRecord(scope.row)">-->
                        <!--招生记录-->
                        <!--</el-link>-->
                        <template v-if="authorityStatus.edit.show">
                            <el-link v-if="scope.row.status == 1" class="tableButton" type="warning"
                                     @click.prevent="changeStatus(scope.row,0)">
                                冻结
                            </el-link>
                            <el-link v-else-if="scope.row.status == 0" class="tableButton" type="success"
                                     @click.prevent="changeStatus(scope.row,1)">
                                恢复
                            </el-link>
                        </template>
                        <el-link class="tableButton" type="danger" @click.prevent="openDel(scope.row)"
                                 v-if="authorityStatus.del.show">
                            删除
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <!--<el-dialog title="添加招生老师(批量导入)" v-model="importDialog" width="600px" :close-on-click-modal="false">-->
        <!--<el-form :model="batchForm" :rules="batchRules" ref="batchForm" label-width="auto">-->
        <!--<el-form-item label="批量导入招生老师模板">-->
        <!--<a href="./files/批量导入招生老师模板.xlsx" class="downMuban"-->
        <!--style="float: left;" download="批量导入招生老师模板.xlsx">点击下载模板</a>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="上传文件" prop="ruleUploadList">-->
        <!--<el-upload style="width: 400px;" ref="ruleUpload"-->
        <!--action="https://jsonplaceholder.typicode.com/posts/"-->
        <!--:on-change="ruleHandleChange" :before-remove="ruleHandleChange"-->
        <!--:file-list="batchForm.ruleFileList"-->
        <!--:auto-upload="false" :on-exceed="ruleHandleExceed"-->
        <!--list-type="text" accept=".xlsx" :limit="batchForm.ruleFileLimit">-->
        <!--<el-button type="primary">选取文件</el-button>-->
        <!--<template #tip>-->
        <!--<div class="el-upload__tip">请编辑上一项下载的模板，然后上传</div>-->
        <!--</template>-->
        <!--</el-upload>-->
        <!--</el-form-item>-->
        <!--</el-form>-->
        <!--<template #footer>-->
        <!--<div class="dialog-footer">-->
        <!--<el-button type="primary" @click="saveImportTeacher">确 定</el-button>-->
        <!--</div>-->
        <!--</template>-->
        <!--</el-dialog>-->
        <!--<el-dialog title="添加招生老师(从组织架构导入)" v-model="organizationDialog" width="600px" :close-on-click-modal="false">-->
        <!--<el-form :model="batchForm" ref="batchForm" label-width="auto">-->
        <!--<el-form-item label="教职工组织架构">-->
        <!--<div style="width: 100%;">-->
        <!--<el-input style="width: 300px;" placeholder="请输入要搜索的内容"-->
        <!--suffix-icon="el-icon-search" v-model="filterText"></el-input>-->
        <!--</div>-->
        <!--<div style="width: 100%;margin-top: 10px;">-->
        <!--<el-tree ref="tree" :data="detailTreeData" node-key="id" default-expand-all-->
        <!--:expand-on-click-node="false" :highlight-current="true" show-checkbox-->
        <!--:filter-node-method="filterNode" :props="defaultProps">-->
        <!--<template #default="{ node}">-->
        <!--<div class="custom-tree-node">-->
        <!--<span>{{ node.label }}</span>-->
        <!--</div>-->
        <!--</template>-->
        <!--</el-tree>-->
        <!--</div>-->
        <!--</el-form-item>-->
        <!--</el-form>-->
        <!--<template #footer>-->
        <!--<div class="dialog-footer">-->
        <!--<el-button type="primary" @click="saveTreeTeacher">确 定</el-button>-->
        <!--</div>-->
        <!--</template>-->
        <!--</el-dialog>-->
        <el-dialog :title="qrcodeTitle" v-model="qrcodeDialog" width="600px" :close-on-click-modal="false">
            <div class="teacherQrcode" ref="imageTofile">
                <img src="../../assets/images/baomingbeijing.png" alt="">
                <img class="teacherQrcodeImg" :src='selMsg.path' alt="">
                <div class="teacherQrcodeText">
                    <span>请扫码报名</span>
                </div>
                <div class="teacherQrcodeText2">
                    <span>{{selMsg.user_name}}</span>
                </div>
                <div class="teacherQrcodeText3">
                    <span>{{selMsg.phone}}</span>
                </div>
                <div class="teacherQrcodeText4">
                    <span>{{userInfo.school_name}}</span>
                </div>
            </div>
            <!--<vue-qrcode value="Hello, World!" :options="{ width: 200 }"></vue-qrcode>-->
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="downQrcode">下 载</el-button>
                </div>
            </template>
        </el-dialog>
        <el-drawer title="招生记录" v-model="drawer" :close-on-click-modal="false" :destroy-on-close="true" size="80%">
            <div class="rtDetailSearch baseSearch clearfloat">
                <div class="baseSearchOne">
                    <div class="baseSearchOneLeft">
                        <span>报名时间：</span>
                    </div>
                    <div class="baseSearchOneRight3 clearfloat">
                        <div style="height: 100%;float: left;font-size: 0;">
                            <el-radio-group v-model="detailForm.radio" size="mini">
                                <el-radio-button label="1">今日</el-radio-button>
                                <el-radio-button label="2">昨日</el-radio-button>
                                <el-radio-button label="3">近七日</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div style="height: 100%;float: left;font-size: 0;margin-left: 10px;">
                            <el-date-picker v-model="detailForm.value1" type="datetimerange" range-separator="-"
                                            start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="baseSearchOne">
                    <div class="baseSearchOneLeft">
                        <span>报名批次：</span>
                    </div>
                    <div class="baseSearchOneRight">
                        <el-select v-model="detailForm.value" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="baseSearchOne">
                    <el-button type="primary" style="margin-left: 20px;">搜索</el-button>
                    <el-button>清空</el-button>
                </div>
            </div>
            <div class="rtDetailOperate baseOperate clearfloat">
                <div class="baseOperateOne">
                    <el-button type="primary">导处记录</el-button>
                </div>
            </div>
            <div class="baseTable">
                <publicTable :publicTableOtherHeight="(detailSearchHeight+16)" :publicTableData="tableData">
                    <el-table-column prop="date" label="报名批次"></el-table-column>
                    <el-table-column prop="name" label="姓名"></el-table-column>
                    <el-table-column prop="name" label="性别"></el-table-column>
                    <el-table-column prop="name" label="身份证号"></el-table-column>
                    <el-table-column prop="name" label="联系方式"></el-table-column>
                    <el-table-column prop="name" label="报名时间" width="110"></el-table-column>
                </publicTable>
            </div>
            <div class="basePage" style="margin-top: 4px;">
                <publicPage :publicPageTotal="{total:total}"></publicPage>
            </div>
        </el-drawer>
        <el-dialog title="添加招生老师" v-model="addTeacherDialog" width="800px" :close-on-click-modal="false">
            <el-form label-width="200px">
                <el-form-item label="添加方式">
                    <el-radio-group v-model="userForm.type">
                        <el-radio label="1">单个添加</el-radio>
                        <el-radio label="2">从组织架构导入</el-radio>
                        <el-radio label="3">批量导入</el-radio>
                    </el-radio-group>
                </el-form-item>
                <template v-if="userForm.type == '1'">
                    <el-form-item label="教职工学工号" prop="name">
                        <el-input v-model="userForm.t_card_number" style="width: 300px;"></el-input>
                    </el-form-item>
                </template>
                <template v-if="userForm.type == '2'">
                    <el-form-item label="教职工组织架构">
                        <div style="width: 100%;">
                            <el-input style="width: 300px;" placeholder="请输入要搜索的内容"
                                      suffix-icon="el-icon-search" v-model="filterText"></el-input>
                        </div>
                        <div style="width: 100%;margin-top: 10px;">
                            <el-tree ref="tree" :data="detailTreeData" node-key="id" default-expand-all
                                     :expand-on-click-node="false" :highlight-current="true" show-checkbox
                                     :filter-node-method="filterNode" :props="defaultProps">
                                <template #default="{ node}">
                                    <div class="custom-tree-node">
                                        <span>{{ node.label }}</span>
                                    </div>
                                </template>
                            </el-tree>
                        </div>
                    </el-form-item>
                </template>
                <template v-if="userForm.type == '3'">
                    <el-form-item label="批量导入招生老师模板">
                        <a href="./files/批量导入招生老师模板.xlsx" class="downMuban"
                           style="float: left;" download="批量导入招生老师模板.xlsx">点击下载模板</a>
                    </el-form-item>
                    <el-form-item label="上传文件" prop="ruleUploadList">
                        <el-upload style="width: 400px;" ref="ruleUpload"
                                   action="https://jsonplaceholder.typicode.com/posts/"
                                   :on-change="ruleHandleChange" :before-remove="ruleHandleChange"
                                   :file-list="batchForm.ruleFileList"
                                   :auto-upload="false" :on-exceed="ruleHandleExceed"
                                   list-type="text" accept=".xlsx" :limit="batchForm.ruleFileLimit">
                            <el-button type="primary">选取文件</el-button>
                            <template #tip>
                                <div class="el-upload__tip">请编辑上一项下载的模板，然后上传</div>
                            </template>
                        </el-upload>
                    </el-form-item>
                </template>

            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" v-if="userForm.type == 1 || userForm.type == 2" @click="saveTreeTeacher">确
                        定
                    </el-button>
                    <el-button type="primary" v-if="userForm.type == 3" @click="saveImportTeacher">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import {businessGet, businessPost, businessPut, businessDel} from '../../api/business.js'

    import Global from '../../Global.js'

    import {utilsUploadFile, utilsExportExcel, utilsDownImg, utilsNotificationJump} from '../../utils/utils.js'

    import {utilsGetAuthority} from '../../utils/utils.js'

    export default {
        name: "recruitTeacher",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                userInfo: JSON.parse(localStorage.getItem(Global.GlobalLocalName2)),
                searchForm: {
                    user_name: '',
                    card_number: '',
                },
                options: [
                    {
                        value: '选项1',
                        label: '黄金糕',
                    },
                    {
                        value: '选项2',
                        label: '双皮奶',
                    },
                    {
                        value: '选项3',
                        label: '蚵仔煎',
                    },
                    {
                        value: '选项4',
                        label: '龙须面',
                    },
                    {
                        value: '选项5',
                        label: '北京烤鸭',
                    },
                ],
                searchHeight: 300,
                tableConfigure: {
                    selection: true,
                },
                tableData: [],
                tableDataSel: [],
                total: 0,
                selMsg: null,
                ruleForm: {
                    name: '',
                    num: 0,
                },
                rules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                importDialog: false,
                batchForm: {
                    ruleFileList: [],
                    ruleFileLimit: 1,
                    ruleUploadList: [],
                },
                batchRules: {
                    ruleUploadList: [
                        {type: 'array', required: true, message: '该项不能为空', trigger: 'blur'}
                    ],
                },
                organizationDialog: false,
                filterText: '',
                detailTreeData: [],
                defaultProps: {
                    children: 'son',
                    label: 'name',
                },
                qrcodeTitle: '',
                qrcodeDialog: false,
                drawer: false,
                detailForm: {
                    radio: '',
                    value1: [],
                },
                detailSearchHeight: 300,
                detailTableData: [],
                detailTotal: 0,
                authorityStatus: {
                    zuzhijiagouAdd: {
                        method: 'post',
                        url: '/admin/v1/recruit_teacher',
                        show: false,
                    },
                    fileAdd: {
                        method: 'post',
                        url: '/admin/v1/recruit_teacher/import',
                        show: false,
                    },
                    teacherExport: {
                        method: 'get',
                        url: '/admin/v1/recruit_teacher_export',
                        show: false,
                    },
                    edit: {
                        method: 'put',
                        url: '/admin/v1/recruit_teacher/{id}',
                        show: false,
                    },
                    del: {
                        method: 'delete',
                        url: '/admin/v1/recruit_teacher/{id}',
                        show: false,
                    },
                },
                addTeacherDialog: false,
                userForm: {
                    type: '1',
                    t_card_number: '',
                }
            }
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        mounted() {
            utilsGetAuthority(this.authorityStatus)
            this.getTreeData();
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                this.searchHeight = searchHeight;
                this.cleanAll();
            })
        },
        methods: {
            openAddTeacher() {
                this.userForm.type = '1';
                this.userForm.t_card_number = '';
                this.openImport();
                this.addTeacherDialog = true;
            },
            batchDel() {
                if (this.tableDataSel.length > 0) {
                    this.$confirm('此操作将批量删除选中的人员, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        let newArray = [];
                        this.tableDataSel.forEach(item => {
                            newArray.push(item.id)
                        })
                        let url = '/admin/v1/recruit_teacher/' + newArray.join(',');
                        businessDel(url, {}).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }).catch(() => {

                    })
                }
                else {
                    this.$message({
                        showClose: true,
                        message: '请先选择要删除的人员',
                        type: 'warning'
                    });
                }
            },
            openDel(val) {
                this.$confirm('删除后招生老师无法使用个人招生二维码进行招生，历史招生记录会自动清空，是否确认删除？', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/recruit_teacher/' + val.id;
                    let data = {}
                    businessDel(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            openFreeze() {
                this.$confirm('冻结后招生老师无法使用个人招生二维码进行招生，历史招生记录仍然保留，是否确认冻结？', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {

                }).catch(() => {

                })
            },
            openRecord() {
                this.drawer = true;
                this.$nextTick(() => {
                    let baseSearchDom = document.querySelector('.rtDetailSearch');
                    let baseOperateDom = document.querySelector('.rtDetailOperate');
                    let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                    this.detailSearchHeight = searchHeight;
                })
            },
            changeStatus(val, status) {
                let msg = '';
                if (status == 1) {
                    msg = '解除冻结'
                }
                else if (status == 0) {
                    msg = '冻结'
                }
                this.$confirm('此操作将' + msg + ', 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/recruit_teacher/' + val.id;
                    let data = {
                        status: status
                    }
                    businessPut(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            downQrcode() {
                utilsDownImg(this.$refs.imageTofile, this.selMsg.user_name + '的招生码')
            },
            openQrcode(val) {
                this.selMsg = val;
                let url = '/admin/v1/recruit_teacher/qrcode?teacher_no=' + this.selMsg.card_number;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.selMsg.path = res.data.data.path;
                        this.qrcodeTitle = this.selMsg.user_name + '的招生码'
                        this.qrcodeDialog = true;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openExport() {
                let url = '/admin/v1/recruit_teacher?is_export=1';
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        url = url + '&' + k + '=' + this.searchForm[k];
                    }
                }
                utilsExportExcel(url)
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            saveTreeTeacher() {
                let url = '/admin/v1/recruit_teacher';
                let data = {}
                if (this.userForm.type == 1) {
                    if (this.userForm.t_card_number == '') {
                        this.$message({
                            showClose: true,
                            message: '请填写学工号',
                            type: 'warning'
                        });
                        return
                    }
                    data.t_card_number = this.userForm.t_card_number;
                }
                else if (this.userForm.type == 2) {
                    // var a = this.$refs.assignTree.getCheckedKeys().concat(this.$refs.assignTree.getHalfCheckedKeys());
                    let newArray = this.$refs.tree.getCheckedKeys();
                    if (newArray.length <= 0) {
                        this.$message({
                            showClose: true,
                            message: '请选择组织架构',
                            type: 'warning'
                        });
                        return
                    }
                    data.org_ids = newArray.join(',')
                }
                businessPost(url, data).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.addTeacherDialog = false;
                        this.getTableData();
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openOrganization() {
                this.organizationDialog = true;
            },
            saveImportTeacher() {
                if (this.batchForm.ruleUploadList.length <= 0) {
                    this.$message({
                        showClose: true,
                        message: '请选择上传文件',
                        type: 'warning'
                    });
                    return
                }
                console.log(this.batchForm.ruleUploadList);
                utilsUploadFile(this.batchForm.ruleUploadList[0].raw, 'import').then(res => {
                    let url = '/admin/v1/recruit_teacher/import';
                    let data = {
                        file_id: res.file_id,
                    }
                    businessPost(url, data).then(res2 => {
                        if (res2.data.status == 200) {
                            utilsNotificationJump(res2.data.message, '/systemImport?module=recruit_teacher')
                            this.addTeacherDialog = false;
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res2.data.message,
                                type: 'warning'
                            });
                        }
                    })
                })
                // this.$refs.batchForm.validate((valid) => {
                //     if (valid) {
                //         utilsUploadFile(this.batchForm.ruleUploadList[0].raw, 'import').then(res => {
                //             let url = '/admin/v1/recruit_teacher/import';
                //             let data = {
                //                 file_id: res.file_id,
                //             }
                //             businessPost(url, data).then(res2 => {
                //                 if (res2.data.status == 200) {
                //                     utilsNotificationJump(res2.data.message, '/systemImport?module=recruit_teacher')
                //                     this.addTeacherDialog = false;
                //                     this.getTableData();
                //                 }
                //                 else {
                //                     this.$message({
                //                         showClose: true,
                //                         message: res2.data.message,
                //                         type: 'warning'
                //                     });
                //                 }
                //             })
                //         })
                //     }
                // })
            },
            ruleHandleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 ` + this.batchForm.ruleFileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            ruleHandleChange(file, fileList) {
                this.batchForm.ruleUploadList = fileList;
            },
            openImport() {
                this.batchForm.ruleFileList = [];
                this.batchForm.ruleFileLimit = 1;
                this.batchForm.ruleUploadList = [];
                // this.importDialog = true;
            },
            tableMsgFromSon(event) {
                this.tableDataSel = event;
            },
            cleanAll() {
                this.searchForm.user_name = '';
                this.searchForm.card_number = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/recruit_teacher?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        url = url + '&' + k + '=' + this.searchForm[k];
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        this.tableData = res.data.data.list;
                    }
                })
            },
            getTreeData() {
                let url = '/admin/v1/organization?type=2';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let msg = {
                            id: 0,
                            name: this.userInfo.school_name
                        }
                        if (res.data.data.length > 0) {
                            msg.son = res.data.data;
                        }
                        this.detailTreeData = [msg];
                        this.options = res.data.data;
                    }
                })
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .teacherQrcode {
        width: 359px;
        height: 469px;
        box-sizing: border-box;
        position: relative;
        margin: 0 auto;
    }

    .teacherQrcodeImg {
        width: 172px;
        height: 172px;
        box-sizing: border-box;
        position: absolute;
        top: 134px;
        left: 50%;
        transform: translateX(-50%);
    }

    .teacherQrcodeText {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        font-size: 22px;
        color: #ffffff;
        position: absolute;
        top: 50px;
        left: 0;
    }

    .teacherQrcodeText2 {
        width: 100%;
        box-sizing: border-box;
        text-align: right;
        font-size: 18px;
        color: #ffffff;
        position: absolute;
        top: 336px;
        left: 0;
        padding-right: 76px;
    }

    .teacherQrcodeText3 {
        width: 100%;
        box-sizing: border-box;
        text-align: right;
        font-size: 12px;
        color: #ffffff;
        position: absolute;
        top: 366px;
        left: 0;
        padding-right: 76px;
    }

    .teacherQrcodeText4 {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        font-size: 16px;
        color: #4194F2;
        position: absolute;
        top: 418px;
        left: 0;
    }

</style>
